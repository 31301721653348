import React, { useState, useEffect } from 'react';
import './Hero.css'; // You can create this CSS file for custom styling
import videoSource from '../assets/background-video.mp4';
import hero from '../assets/hero.png'; 
import { Link, Element } from 'react-scroll'; // Import Link and Element

function HeroSection() {
  const [typedText, setTypedText] = useState('');
  const originalText = "IAM modernization with Logorn’s expertise in design and implementation of seamless integrations";
  const [animateText, setAnimateText] = useState(false);
  
  useEffect(() => {
    const typeText = () => {
      let currentIndex = 0;
      const textInterval = setInterval(() => {
        if (currentIndex <= originalText.length) {
          setTypedText(originalText.substring(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(textInterval);
        }
      }, 50); // Decreased interval time to speed up animation (50 milliseconds)
    };

    typeText();
    setAnimateText(true); // Trigger the animation after typing is complete

    // Add an event listener to detect scroll position
    window.addEventListener('scroll', handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle scroll and show the overlay image
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const section = document.querySelector('.hero-section');
    const sectionTop = section.getBoundingClientRect().top;

    // If the top of the section is in the viewport, show the overlay image
  
  };

  return (
    <Element name="herosection" className="herosection">
    <div className="hero-section">
      <video autoPlay loop muted className="video-background">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hero-content">
        <h1
          style={{
            bottom: animateText ? '0' : '-50px', 
            transition: 'bottom 1s ease'
          }}
        >
          Identity and Access Management
        </h1>
        <br/>
        <p>{typedText}</p>
        <button className="custom-button">
        <Link
            to="aboutsection" // This should match the name you set for the AboutSection
            spy={true}
            smooth={true}
            duration={500}
          >Show More</Link></button>
      </div>
     
   
    </div>
    </Element>
  );
}

export default HeroSection;

import React, { useState, useEffect, useRef } from 'react';
import './Footer.css';
import logo from '../assets/logo.png';
import { Link } from 'react-scroll'; 
function Footer() {
  const [isVisible, setIsVisible] = useState(true);
  const footerRef = useRef(null);

  useEffect(() => {
    
        const footerObserver = new IntersectionObserver(
          (entries) => {
   
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
              }
            });
          },
          { threshold: 0.5 }
        );
  

    if (footerRef.current) {
      footerObserver.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        footerObserver.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <footer
      className={`footer ${isVisible ? 'footer-animate show' : 'footer-animate'}`}
      ref={footerRef}
    >
      <div className="footer-content">
        <div className="logo">
          <img src={logo} width="140" height="30" className="d-inline-block align-top" alt="logo" />
        </div>
        <ul className="menu">
          <li>
            <Link
              to="herosection"
              spy={true}
              smooth={true}
              offset={-70} 
              duration={500}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="aboutsection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="productsection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              Product
            </Link>
          </li>
          <li>
            <Link
              to="servicesection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="solutionsection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              Solutions
            </Link>
          </li>
          <li>
            <Link
              to="contactsection"
              spy={true}
              smooth={true}
              offset={-70} // Adjust the offset as needed
              duration={500}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="copyright">
        &copy; {new Date().getFullYear()} Logorn. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
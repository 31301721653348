import React, { useEffect, useState } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import './About.css';
import about from '../assets/about.png';
import { Element } from 'react-scroll'; // Import Link and Element

function About() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about');
      if (aboutSection) {
        const aboutSectionTop = aboutSection.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Define the scroll threshold (you can adjust this value)
        const scrollThreshold = windowHeight * 0.7;

        // Check if the about section is in the viewport
        if (aboutSectionTop < scrollThreshold) {
          setAnimate(true);
          // Remove the scroll event listener after the animation triggers
          window.removeEventListener('scroll', handleScroll);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ParallaxProvider>
      <Element name="aboutsection" className="about-section">
         <div className="text-center about-section">
           
              <h2 className="about-heading">
                About Us
              </h2>
          
            <hr className="underline" />
          </div>
      <div className={`about ${animate ? 'animate-in' : ''}`}>
        <div
          className={`about-left ${animate ? 'animate-in' : ''}`}
          style={{ backgroundImage: `url(${about})`, backgroundSize: 'cover', display: 'flex',flexDirection:'column',
          textAlign:'left', justifyContent: 'center',paddingLeft:'30px', }}
        >
          <Parallax y={[-10, 10]} tagouter="figure">
            <h1>About</h1>
         
          </Parallax>
          

          <Parallax y={[-20, 20]} tagouter="figure">
            
            <h2 class="mainheading">Elevate Your Digital Security with Logorn's IAM Expertise</h2>
          </Parallax>
        </div>
        <div className={`about-right ${animate ? 'animate-in' : ''}`}>
          <div className="about-content">
            <Parallax y={[-30, 30]} tagouter="figure">
              <p>In today's digital landscape, Logorn specializes in Identity and Access Management (IAM) solutions that modernize security. We seamlessly integrate cloud applications, safeguarding your digital assets.</p>
            </Parallax>
            <div className="about-approach">
              <Parallax y={[-40, 40]} tagouter="figure">
                <h2>Our Approach</h2>
              </Parallax>
              <Parallax y={[-50, 50]} tagouter="figure">
                <ul>
                  <li><strong>Assessment:</strong> Tailored solutions based on your needs and security demands.</li>
                  <li><strong>Architecture:</strong> Cutting-edge design for adaptability and efficiency.</li>
                  <li><strong>Implementation:</strong> Expert execution for long-term success.</li>
                  <li><strong>Cyber Strategy:</strong> Balancing security and innovation for rapid growth.</li>
                </ul>
              </Parallax>
            </div>
            <div className="about-vision">
              <Parallax y={[-60, 60]} tagouter="figure">
                <h2>Our Vision</h2>
              </Parallax>
              <Parallax y={[-70, 70]} tagouter="figure">
                <p>Empower organizations with advanced IAM solutions for enhanced security, access management, and scalability in the digital era.</p>
              </Parallax>
            </div>
          </div>
        </div>
      </div>
      </Element>
    </ParallaxProvider>
  );
}

export default About;

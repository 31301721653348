import React from 'react';
import './Details.css'; // Create a CSS file for styling

const Details = () => {
  const services = [
    {
      title: 'Assessment',
    },
    {
      title: 'Architecture',
    },
    {
      title: 'Implementation',
    },
    {
      title: 'Cyber Strategy',
    },
  ];

  const renderServices = () => {
    const angle = 360 / services.length;
    let rotation = -90; // Start at the top of the circle

    return services.map((service, index) => {
      const transform = `rotate(${rotation}deg)`;
      rotation += angle;

      return (
        <div
          key={index}
          className="circular-service"
          style={{ transform }}
        >
          <div className="circular-title">{service.title}</div>
          <div className="circular-explanation">{service.explanation}</div>
        </div>
      );
    });
  };

  return (
    <div className="circular-container">
      <div className="circular-center">Services</div>
      {renderServices()}
    </div>
  );
};

export default Details;

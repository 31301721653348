import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'; // Import Parallax
import './Solution.css';
import { Link, Element } from 'react-scroll'; // Import Link and Element
import backgroundImg from '../assets/solution3.png';
const solutionDetails = [
  {
    title: 'Identity Governance',
    description:
      "Reinforce Data Security: Embrace Identity Governance. Our system ensures robust protection by efficiently managing and reducing risks tied to user access. With cutting-edge tech and expertise, we fortify your defenses, guaranteeing peace of mind for business growth. Join us for a resilient data security landscape, safeguarding your information.",
  },
  {
    title: 'Access Management',
    description:
      'Safe access to your systems starts with an Access Management solution implementing Single Sign-On for easy access and Multi-Factor authentication for safety. Protect against the latest threats and drive personalization using the world’s only dynamic orchestration and intelligence engine, which captures rich context to make continuous access decisions.',
  },
  {
    title: 'PAM',
    description:
      "Enhance Security: Embrace Privileged Access Management. In your organization, influential identities require control. Our solution delivers precise access management, bolstering security. Trust our expertise to thwart threats and permit only authorized users, ensuring data protection and peace of mind.",
  },
  {
    title: 'Directory Services',
    description:
      "Empower Your Growth: Harness a High-Performance Directory Service. Our robust solution is tailor-made for scaling rapidly, effortlessly managing extensive transaction loads, and staying ahead of evolving security and privacy demands. Elevate your infrastructure with a service that's built to thrive in the modern digital landscape.",
  },
];

function Solution() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const solutionSection = document.querySelector('.solution-section');
      const sectionTop = solutionSection.offsetTop;
      const windowHeight = window.innerHeight;
      if (window.pageYOffset >= sectionTop - windowHeight && !isAnimated) {
        setIsAnimated(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimated]);

  return (
    <Element name="solutionsection" className="solution-section" >
        <div className='solution-section'>
    <ParallaxProvider>
      {/* <VideoBackground /> */}

      <div className="text-center mb-4" >
            <Parallax y={[-10, 10]} tagouter="h2">
              <h2 className={`solution-heading ${isAnimated ? 'solution-heading-entered' : ''}`}>
                Solutions
              </h2>
            </Parallax>
            <hr className={`mb-4 underline ${isAnimated ? 'underline-entered' : ''}`} />
          </div>
        <div  style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize:`cover` }} className= "solution-main-section">
       
          <Row>
            {solutionDetails.map((solution, index) => (
              <Col md={3} key={index}>
                <Link to={`solution-subsection-${index}`} smooth={true} duration={500}>
                  <div
                    className={`solution-subsection ${
                      isAnimated ? `solution-subsection-enter-${index}` : ''
                    }`}
                    
                  >
                    <Parallax x={[-20, 20]} tagouter="div">
                        {/* Subsection Heading */}
                        <div className="solution-subsection-heading">
                        <h3>{solution.title}</h3>
                      </div>

                      {/* Divider */}
                      <div className="solution-subsection-divider"></div>

                      <div className={`solution-content ${isAnimated ? 'solution-subsection-entered' : ''} text-center`}>
                      
                        <p>{solution.description}</p>
                      </div>
                    </Parallax>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      

          </ParallaxProvider>
          </div>
          </Element>
  );
}

export default Solution;

import React, { useState, useEffect, useRef } from 'react';
import { Link as ScrollLink, animateScroll  } from 'react-scroll';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/logo.png';
import './Header.css'; 

function Header() {
  const logoStyles = {
  };

  const navLinkStyles = {
     };

  const activeNavLinkStyles = {
   };

  const headerStyles = {
    position: 'fixed', 
    top: 0, 
    width: '100%', 
    zIndex: '100000',
    height: '80px', 
    backgroundColor: 'white',
    padding: '20px 40px',
  };

  const [expanded, setExpanded] = useState(false);
  const menuRef = useRef(null);

  const handleMenuItemClick = () => {
    setExpanded(false);
  };

  const handleNavLinkHover = (event) => {
    if (!event.target.classList.contains('active')) {
      event.target.style.color = 'rgb(21, 84, 161)'; 
    }
  };

  const handleLogoHover = (event) => {
    event.target.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.5)';
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div style={headerStyles}>
      <Navbar expand="lg" className="custom-navbar" expanded={expanded} ref={menuRef}>
        <Navbar.Brand href="#">
          <img
            src={logo}
            
            className="d-inline-block align-top header-image"
            alt="logo"
            style={logoStyles}
            onMouseEnter={handleLogoHover}
            onMouseLeave={(event) => (event.target.style.boxShadow = 'none')}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(!expanded)} className="custom-menu-toggle"
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0 d-flex">
            <ScrollLink to="herosection"  spy={true}
              smooth={true}
              offset={-80} // Adjust this offset based on your header's height
              duration={500}
              className="fixed-nav-link"
              onMouseEnter={handleNavLinkHover}
              onMouseLeave={(event) => (event.target.style = navLinkStyles)}
              onClick={handleMenuItemClick}
              activeClass='active'
              
              // {activeNavLinkStyles} // Apply active styles to the active tab
              
            >
              Home
            </ScrollLink>
            <ScrollLink
              to="aboutsection"   spy={true}
              smooth={true}
              offset={-80} // Adjust this offset based on your header's height
              duration={500}
              className="fixed-nav-link"
              onMouseEnter={handleNavLinkHover}
              onMouseLeave={(event) => (event.target.style = navLinkStyles)}
              onClick={handleMenuItemClick}
              style={activeNavLinkStyles} // Apply active styles to the active tab
            >
              About Us
            </ScrollLink>
              <ScrollLink
                to="productsection"  spy={true}
              smooth={true}
              offset={-80} // Adjust this offset based on your header's height
              duration={500}
                className="fixed-nav-link"
                onMouseEnter={handleNavLinkHover}
                onMouseLeave={(event) => (event.target.style = navLinkStyles)}
                onClick={handleMenuItemClick} style={activeNavLinkStyles} // Apply active styles to the active tab
              >
                Products
              </ScrollLink>
              <ScrollLink
                to="servicesection"  spy={true}
                smooth={true}
                offset={-80} // Adjust this offset based on your header's height
                duration={500}
                className="fixed-nav-link"
                onMouseEnter={handleNavLinkHover}
                onMouseLeave={(event) => (event.target.style = navLinkStyles)}
                onClick={handleMenuItemClick} style={activeNavLinkStyles} // Apply active styles to the active tab
              >
                Services
              </ScrollLink>
              <ScrollLink
                to="solutionsection"
                className="fixed-nav-link"
                onMouseEnter={handleNavLinkHover}
                onMouseLeave={(event) => (event.target.style = navLinkStyles)}
                onClick={handleMenuItemClick} style={activeNavLinkStyles} // Apply active styles to the active tab
              >
                Solutions
              </ScrollLink>
              <ScrollLink
                to="contactsection"  spy={true}
                smooth={true}
                offset={-80} // Adjust this offset based on your header's height
                duration={500}
                className="fixed-nav-link"
                onMouseEnter={handleNavLinkHover}
                onMouseLeave={(event) => (event.target.style = navLinkStyles)}
                onClick={handleMenuItemClick} style={activeNavLinkStyles} // Apply active styles to the active tab
              >
                Contact Us
              </ScrollLink>
            </Nav>
          </Navbar.Collapse>
        
      </Navbar>
    </div>
  );
}

export default Header;

import './App.css';
import AboutSection from './components/AboutSection';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Product from './components/Product';
import Solution from './components/Solution';
import ServicesComponent from './components/ServicesComponent';
import Contact from './components/Contact';

function App() {
  return (
    <div className="app-container">
      <Header />
      <HeroSection />
      <AboutSection />
      <Product />
      <ServicesComponent /> 
      <Solution /> 
      <Contact />
      <Footer /> 
      </div> 
  );
}

export default App;

import React from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import contact from '../assets/solution4.png';
import './Contact.css';
import { Element } from 'react-scroll'; // Import Link and Element

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string(),
  message: yup.string().required('Message is required'),
});
// const url = 'http://localhost:3003/submit'; 
const Contact = () => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      email: '',
      phone: '',
      message: '',
    },
  });



// const onSubmit = async (data) => {
//   try {
//     // Make a POST request to your backend API endpoint
//     const response = await axios.post('http://localhost:3003/submit', data);
//     if (response.status === 200) {
//       // Handle success, e.g., show a success message to the user
//       console.log('Email sent successfully');
//     }
//   } catch (error) {
//     // Handle error, e.g., show an error message to the user
//     console.error('Error sending email', error);
//   }
// };

  return (
    <Element name="contactsection" className="contact-section">
      
    <div className="contact-section">
      <div className="text-center mb-4">
        <h2 className="contact-heading">Contact Us</h2>
        <hr className={`mb-4 underline`} />
      </div>
      <Row>
        <Col md={6}>
          <div className="image-container">
            <Image src={contact} alt="Contact" fluid height="100%" />
            <div className="border-animation"></div>
          </div>
        </Col>
        <Col md={6}>
          <div className="custom-form">
            {/* <h3 className="form-title">Get in Touch</h3> */}
            <Form className="p-3">
              {/* </Form>onSubmit={handleSubmit(onSubmit)}  action="http://localhost:3003/submit" method="post"> */}
              <Form.Group controlId="firstName" className="form-group">
                <Form.Label className="form-label">Name</Form.Label>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text" size="sm"
                      placeholder="Enter your name"
                      required
                      className=" form-control "
                    />
                  )}
                />
              </Form.Group>

              <Form.Group controlId="email" className="form-group">
                <Form.Label className="form-label">Work Email</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                    size="sm"                      {...field}
                      type="email"
                      placeholder="Enter your work email"
                      required
                      className="form-control"
                    />
                  )}
                />
              </Form.Group>

              <Form.Group controlId="phone" className="form-group">
                <Form.Label className="form-label">Contact No</Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field} size="sm"
                      type="tel"
                      placeholder="Enter your phone number"
                      className="small-textbox form-control"
                    />
                  )}
                />
              </Form.Group>

              <Form.Group controlId="message" className="form-group">
                <Form.Label className="form-label">What can we help you with?</Form.Label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      as="textarea"
                      rows={2}
                      placeholder="Your message"
                      required
                      className="small-textbox form-control"
                    />
                  )}
                />
              </Form.Group>

              {errors.firstName && <p className="error-text">{errors.firstName.message}</p>}
              {errors.email && <p className="error-text">{errors.email.message}</p>}
              {errors.message && <p className="error-text">{errors.message.message}</p>}

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
    </Element>
  );
};

export default Contact;

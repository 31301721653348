import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'; // Import Parallax
import product1 from '../assets/product1.png';
import product2 from '../assets/product2.png';
import product3 from '../assets/product3.png';
import { Link, Element } from 'react-scroll'; // Import Link and Element
import './Product.css';
const productDetails = [
  {
    backgroundImage: `url(${product1})`,
    title: 'Sign Identity',
    description:
      'Customizable user interface to manage user self-service capabilities and applications access for end-to-end Identity and Access Management that works with all the leading Identity Providers that provide various IAM services.',
  },
  {
    backgroundImage: `url(${product2})`,
    title: 'AuthNGateway',
    description: 'SSO plugin that provides the authentication gateway services to web applications hosted on-prem or in the cloud.',
  },
  {
    backgroundImage: `url(${product3})`,
    title: 'APIGateway',
    description: 'User provisioning services that provide various endpoints serving as a single pathway for Rest API calls among various applications.',
  },
];

function Product() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const productSection = document.querySelector('.product-section');
      const sectionTop = productSection.offsetTop;
      const windowHeight = window.innerHeight;
      if (window.pageYOffset >= sectionTop - windowHeight && !isAnimated) {
        setIsAnimated(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimated]);

  return (
    
    <ParallaxProvider>
      <Element name="productsection" className="product-section">
        <div className='product-section'>
          <div className="text-center mb-4">
            <Parallax y={[-20, 20]} tagouter="h2"> {/* Parallax for the product heading */}
              <h2 className={` product-heading ${isAnimated ? 'product-heading-entered' : ''}`}>
                Products
              </h2>
            </Parallax>
            <hr className={` underline ${isAnimated ? 'underline-entered' : ''}`} />
          </div>
          <Row>
            {productDetails.map((product, index) => (
              <Col md={4} key={index}>
                <Link to={`product-subsection-${index}`} smooth={true} duration={500}> {/* Smooth scrolling link */}
                  <div
                    className={`product-subsection ${
                      isAnimated ? `product-subsection-enter-${index}` : ''
                    }`}
                    style={{ backgroundImage: product.backgroundImage }}
                  >
                    <Parallax x={[-20, 20]} tagouter="div"> {/* Parallax for the product subsection */}
                      <div className={`product-content ${isAnimated ? 'product-subsection-entered' : ''} text-center`}>
                        <h3>{product.title}</h3>
                        <p>{product.description}</p>
                      </div>
                    </Parallax>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </Element>

      
    </ParallaxProvider>
  );
}

export default Product;
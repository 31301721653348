import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './ServicesComponent.css'; 
import Details from './Details';
import { Link, Element } from 'react-scroll'; 
const services = [
  {
    title: 'Assessment',
    description:
      'After we do a full discovery on your current landscape, needs and pain points, we present solutions that fit your environment, company business needs, security demands and user criteria. We know how to lead you to an effective and usable system.',
  },
  {
    title: 'Architecture',
    description:
    'Our architects prioritize dynamic, efficient solutions that adapt to evolving needs. With extensive experience and a commitment to innovation, we collaborate closely with clients to align technical excellence with their goals.'
  },
  {
    title: 'Implementation',
    description:
      'Whether you are changing directions with technology or starting from scratch, we can implement with adaptability and pose your environment for long-term success.',
  },
  {
    title: 'Cyber Strategy',
    description:
      'Enable your organization to innovate freely and grow quickly by building a comprehensive cyber strategy that balances enterprise security with your organization risk appetite.',
  },
];

const ServiceComponent = () => {
  return (
    <Element name="servicesection" className="services-section">
    <div className='services-section'>
    <div className="text-center mb-4 service-section">
   
      <h2 className={`service-heading `}>
        Services
      </h2>
    
    <hr className={`mb-4 underline `} />
  </div>

    <div className="my-carousel">
          <Carousel>
        {services.map((service, index) => (
          <Carousel.Item key={index} className={`slide-${index}`}>
            <Container>
              <Row>
              <Col lg={3} md={3}className="details-column">
         
            <Details />
          </Col >
                <Col  lg={8} md={8}>
                  <div className="carousel-content">
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
    
    </div>
    </Element>
  );
};

export default ServiceComponent;

